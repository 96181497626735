export default {
  apiKey: "AIzaSyAKXT6MmAzEjDnMf_kehSxRxJjBbZy9DTM",
  authDomain: "digital-16dd2.firebaseapp.com",
  databaseURL: "https://digital-16dd2.firebaseio.com",
  projectId: "digital-16dd2",
  storageBucket: "digital-16dd2.appspot.com",
  messagingSenderId: "87516403602",
  appId: "1:87516403602:web:a1891a94490bd6529ad34b",
  measurementId: "G-JB10PVL71V"
};
